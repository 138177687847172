$(document).ready(function() {
    "use strict";
    //Hide HoneyPot
    $('input#workemail').hide();
    //Change form based on radio button input
    $('input[name="choice-pay"]').change(function(){
        if($('#choice-pay-self').prop('checked')){
            // show self-pay message
            $(".self-pay-message-div").show("slow");
            $('#self-pay-message').prop('required',true);
            // hide sponsorship message
            $(".sponsorship-message-div").hide("slow");
            $('#sponsorship-message').prop('required',false);
            $('#sponsor-amount').prop('required',false);
        }else if ($('#choice-sponsorship').prop('checked')){
            // show sponsorship message
            $(".sponsorship-message-div").show("slow");
            $('#sponsorship-message').prop('required',true);
            $('#sponsor-amount').prop('required',true);
            // hide self-pay message
            $(".self-pay-message-div").hide("slow");
            $('#self-pay-message').prop('required',false);
        }
  });
});
  // Wait for the DOM to be ready
$(function() {
  // Initialize form validation on the registration form.
  // It has the name attribute "registration"
  $("form[name='order-form']").validate({
    // Specify validation rules
    rules: {
      // The key name on the left side is the name attribute
      // of an input field. Validation rules are defined
      // on the right side
      firstname: "required",
      lastname: "required",
      email: {
        required: true,
        // Specify that email should be validated
        // by the built-in "email" rule
        email: true
      },
      booknumber: "required"
    },
    // Specify validation error messages
    messages: {
      firstname: "",
      lastname: "",
      email: "",
      booknumber: ""
    },

    highlight: function (element) {
      $(element).closest('.control').removeClass('has-success').addClass('has-error')
  },
  unhighlight: function (element) {
      $(element).closest('.control').removeClass('has-error').addClass('has-success');
  },
    // Make sure the form is submitted to the destination defined
    // in the "action" attribute of the form when valid
    submitHandler: function(form, event) {
      //form.submit();

      event.preventDefault();
  var r = !0,
      s = form,
      e = $(s).siblings(".order-form-result"),
      o;
  // Escape if the honeypot has been filled
  if (!!$("#workemail").val()) return;
  if (!!$("#anothercontact").val()) return;

      var firstname = $("#order-name-input").val();
      var lastname = $("#order-lastname-input").val();
      var mobile = $("#order-mobile-input").val();
      var email = $("#order-email-input").val();
      var quantity = $("#order-quantity-input").val();
      var sponsorAmount = $("#sponsor-amount").val();
      var sponsorMessage = $("#sponsorship-message").val();
      var payMessage = $("#self-pay-message").val();
      var messageLanguage = $("#order-language").val();
      var data = {
         messageLanguage : messageLanguage,
         firstname : firstname,
         lastname : lastname,
         mobile : mobile,
         email : email,
         orderSubscribe : orderSubscribe,
         quantity : quantity,
         sponsorAmount : sponsorAmount,
         sponsorMessage : sponsorMessage,
         payMessage : payMessage }
      $.ajax({
          url: "https://3q2fqt4bx0.execute-api.ap-east-1.amazonaws.com/production/order",
          type: "post",
          dataType: "json",
          crossDomain: "true",
          contentType: "application/json; charset=utf-8",
          data: JSON.stringify(data),

          beforeSend: function(data) {
            $('#submit-order').attr('disabled', true);
            //$('#status').html('<i class="fa fa-refresh fa-spin"></i> Sending Mail...').show();
            if ($("#language").val() == "km") {
                o = '<p class="form-message form-success">កំពុងបញ្ជាទិញ...</p>';
                $('#submit-order').attr('disabled', true);
                $('#submit-order').html('<i class="fa fa-spinner fa-spin fa-2x fa-fw"></i> កំពុងបញ្ជាទិញ...');
            } else {
                o = '<p class="form-message form-success">Ordering...</p>';
                $('#submit-order').html('<i class="fa fa-spinner fa-spin fa-2x fa-fw"></i> Ordering...');
            }
            e.removeClass("hidden").html(o)
        },

        success: function (data) {
            // clear form and show a success message
            // check form language
            if (data == "Yes") {
                if ($("#language").val() == "km") {
                    if (sponsorMessage == "") {
                        var successMessage = "អរគុណ​! អ្នកអាចមកកុម្ម៉ង់សៀវភៅរបស់អ្នកនៅការិយាល័យរបស់យើងនៅភ្នំពេញថ្មី។";
                    } else {
                        var successMessage = "អរគុណ​! អ្នក​តំណាង​យើង​នឹង​ទាក់ទង​លោក​អ្នក​ឆាប់ៗ​នេះ ដើម្បី​ផ្តល់​​ព័ត៌មានឲ្យ​លម្អិត​បន្ថែមទៀត។";
                    }
                    successMessage = "សព្វ​ថ្ងៃ​នេះ យើង​បាន​លក់ «សៀវភៅ​រឿង​ព្រះ​យេស៊ូវ​តាម​រយៈ​ព្រះ​គម្ពីរ» អស់​ហើយ។ ប៉ុន្តែ យើង​កំពុង​តែ​រៀបចំ​បោះពុម្ព​ជា​លើក​ទី២ ហើយ​បាន​កត់​លោក​អ្នក​ចុះ​ក្នុង​បញ្ជី​រង់ចាំ ហើយ​យើង​នឹង​ទាក់​ទង​លោក​អ្នក​ភ្លាម​ពេល​ដែល​សៀវភៅ​នោះ​បោះពុម្ព​រួច។ សូម​អរគុណ!"
                    successMessage = "សព្វ​ថ្ងៃ​នេះ យើង​មិន​ទាន់​មាន សៀវភៅ​ ការលូន់តួ ទេ។ ប៉ុន្តែ យើង​កំពុង​តែ​រៀបចំ​បោះពុម្ព​ ហើយ​បាន​កត់​លោក​អ្នក​ចុះ​ក្នុង​បញ្ជី​រង់ចាំ ហើយ​យើង​នឹង​ទាក់​ទង​លោក​អ្នក​ភ្លាម​ពេល​ដែល​សៀវភៅ​នោះ​បោះពុម្ព​រួច។ សូម​អរគុណ!"

                } else {
                    if (sponsorMessage == "") {
                        var successMessage = "Thank you for your order. You can pick up your books at our office in Phnom Penh Themy.";
                    } else {
                        var successMessage = "Thank you for your order. A representative will be contacting you soon with more details.";
                    }
                    successMessage = "Confessions in Khmer is currently out of stock. But we are preparing for a second printing and have placed you on the waiting list and we will contact you once the second edition is available. Thank you!"
                    successMessage = "Confessions in Khmer is not yet printed. But we are preparing for to print and have placed you on the waiting list and we will contact you once it is available. Thank you!"
                }
                o = '<p class="form-message form-success">' + successMessage + '</p>';
                e.removeClass("hidden").html(o);
            } else {
                if ($("#language").val() == "km") {
                    var errorMessage = "សូម​មេត្តា​បំពេញ​ពេល​ក្រោយ ពីព្រោះ​មាន​កំហុស​ខ្លះៗ​ខណៈ​ពេល​ដែល​លោក​អ្នក​កំពុង​បញ្ជូន​ទម្រង់​បែប​បទ​មក​កាន់​ខាង​យើង។";
                } else {
                    var errorMessage = "Sorry there was a problem. Please try again later.";
                }
                o = '<p class="form-message form-error">' + errorMessage + '</p>';
                e.removeClass("hidden").html(o);
            }
            
            $(s)[0].reset();
            setTimeout(function() {
                e.addClass("hidden").html("");
            }, 10000);
            if ($("#language").val() == "km") {
                $('#submit-order').html('កក់ឥឡូវនេះ');
            }
            else {
                $('#submit-order').html('Order');
            }
            $('#submit-order').attr('disabled', false);
            console.log("Thinks it sent")
          },
        error: function (jqXHR, textStatus, errorThrown) {
            // show an error message
            if ($("#order-language").val() == "km") {
                var errorMessage = 'សូម​មេត្តា​បំពេញ​ពេល​ក្រោយ ពីព្រោះ​មាន​កំហុស​ខ្លះៗ​ខណៈ​ពេល​ដែល​លោក​អ្នក​កំពុង​បញ្ជូន​ទម្រង់​បែប​បទ​មក​កាន់​ខាង​យើង។ កំហុស៖ ' + jqXHR.status;
            } else {
                var errorMessage = 'Sorry, there was an error. Please try again later. ERROR: ' + jqXHR.status;
            }
            o = '<p class="form-message form-error">' + errorMessage + '</p>';
            e.removeClass("hidden").html(o);
            setTimeout(function() {
                e.addClass("hidden").html("");
            }, 5e3);
            if ($("#language").val() == "km") {
                $('#submit-order').html('កក់ឥឡូវនេះ');
            }
            else {
                $('#submit-order').html('Order');
            }
            $('#submit-order').attr('disabled', false);
            console.log("Thinks there was an error")
        }
    });







}
});
});
