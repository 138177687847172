$(document).ready(function() {
  //Hide HoneyPot
  $('#companyemail').addClass('hide');
  $('#submit').attr('disabled', true); // Initially disable the submit button
});

$(function() {
  let turnstileWidgetId = null; // Variable to store the Turnstile widget instance ID

  // Function to enable the submit button when Turnstile is successful
  function onTurnstileSuccess(token) {
      $('#submit').attr('disabled', false); // Enable the submit button
  }

  // Function to handle Turnstile expiration or error
  function onTurnstileExpiredOrError() {
      $('#submit').attr('disabled', true); // Disable the submit button
  }

  // Initialize Turnstile
  window.onload = function() {
      turnstileWidgetId = turnstile.render('#turnstile-container', {
          sitekey: '0x4AAAAAAAjxO7DcYLmXgE9d',  // Replace with your actual site key
          theme: 'light',
          callback: onTurnstileSuccess,          // Token generated
          'error-callback': onTurnstileExpiredOrError,  // Error handling
          'expired-callback': onTurnstileExpiredOrError // Token expired
      });
  };

  // Initialize form validation on the registration form
  $("form[name='contact-form']").validate({
      // Specify validation rules
      rules: {
          fullname: "required",
          email: {
              required: true,
              email: true
          },
          message: "required"
      },
      // Specify validation error messages
      messages: {
          fullname: "",
          email: "",
          message: ""
      },

      highlight: function (element) {
          $(element).closest('.control').removeClass('has-success').addClass('has-error');
      },
      unhighlight: function (element) {
          $(element).closest('.control').removeClass('has-error').addClass('has-success');
      },

      // Override form submission when valid
      submitHandler: function(form, event) {
          event.preventDefault();

          // Get the latest Turnstile token from the widget
          const turnstileToken = turnstile.getResponse(turnstileWidgetId);

          // Prevent form submission if Turnstile token is not set
          if (!turnstileToken) {
              console.log("Problem with token");
              alert("Please complete the Turnstile challenge.");
              return;
          }

          var r = !0,
              s = form,
              e = $(s).siblings(".contact-form-result"),
              o;

          // Escape if the honeypot has been filled
          if (!!$("#companyemail").val()) return;
          if (!!$("#secondcontact").val()) return;

          var fullname = $("#fullname").val();
          var email = $("#email").val();
          var message = $("#message").val();
          var messageLanguage = $("#language").val();
          var data = {
              messageLanguage: messageLanguage,
              fullname: fullname,
              email: email,
              message: message,
              token: turnstileToken  // Add the Turnstile token to the data sent to the server
          };

          $.ajax({
              url: "https://dn8b7u0nvd.execute-api.ap-south-1.amazonaws.com/production/sendMail",
              type: "post",
              dataType: "json",
              crossDomain: "true",
              contentType: "application/json; charset=utf-8",
              data: JSON.stringify(data),

              beforeSend: function(data) {
                  $('#submit').attr('disabled', true);
                  if ($("#language").val() == "km") {
                      o = '<p class="form-message form-success">កំពុង​ផ្ញើ...</p>';
                  } else {
                      o = '<p class="form-message form-success">Sending...</p>';
                  }
                  e.removeClass("hidden").html(o);
              },

              success: function (data) {
                  console.log(data);
                  if (data == "Email sent!") {
                      if ($("#language").val() == "km") {
                          var successMessage = "អរគុណ​សម្រាប់​ការ​ផ្ញើ​សារ​របស់​លោក​អ្នក យើង​ខ្ញុំ​នឹង​ឆ្លើយ​តប​ទៅ​លោក​អ្នក​វិញ តាម​លទ្ធភាព​ដែល​យើងខ្ញុំ​អាច​ធ្វើ​ទៅ​បាន។";
                      } else {
                          var successMessage = "Thank you for your message. We will get back to you as soon as we can.";
                      }
                  }
                  o = '<p class="form-message form-success">' + successMessage + '</p>';
                  e.removeClass("hidden").html(o);
                  $(s)[0].reset();
                  setTimeout(function() {
                      e.addClass("hidden").html("");
                  }, 5000);
                  $('#submit').attr('disabled', false);
              },
              error: function (jqXHR, textStatus, errorThrown) {
                  var errorMessage;
                  if ($("#language").val() == "km") {
                      errorMessage = 'សូម​មេត្តា​បំពេញ​ពេល​ក្រោយ ពីព្រោះ​មាន​កំហុស​ខ្លះៗ ខណៈ​ពេល​ដែល​លោក​អ្នក​កំពុង​បញ្ជូន​ទម្រង់​បែប​បទ​មក​កាន់​ខាង​យើង។ កំហុស៖ ' + jqXHR.status;
                  } else {
                      errorMessage = 'Sorry, there was an error. Please try again later. ERROR: ' + jqXHR.status;
                  }
                  o = '<p class="form-message form-error">' + errorMessage + '</p>';
                  e.removeClass("hidden").html(o);
                  setTimeout(function() {
                      e.addClass("hidden").html("");
                  }, 5000);
                  $('#submit').attr('disabled', false);
              }
          });
      }
  });
});
